import axios from "axios";

// Função auxiliar para obter o valor de um cookie pelo nome
function getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    
    if (parts.length === 2) {
        const cookieValue = parts.pop();
        if (cookieValue) {
            return cookieValue.split(';').shift();
        }
    }
    
    return undefined;
}

const axiosClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
});

axiosClient.interceptors.request.use(function(request){
    const authToken = getCookie('auth_token');
    if (authToken) {
        request.headers.Authorization = `Bearer ${authToken}`;
    }
    return request;
});

export default axiosClient;
